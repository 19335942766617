<template>
	<div>
		<v-header  class="headerTop"></v-header>
		<div  class="listWOW">
			<div class="row">
				<div class="slideInLeft">
					<div class="wow rollIn"> <img src="../../assets/images/solution/stadium/stadium_01.png" /> </div>
				</div>
				<div class="slideInRight">
					<div class="wow bounceInDown">	<img src="../../assets/images/solution/stadium/stadium_01_title.png" />	</div>
<!--					<div class="wow lightSpeedIn cursorHand  wowMargin"  @click="schoolpath()"><img src="../../assets/images/schoolonline_10.png" /></div>-->
				</div>
			</div>
			<div class="row" style="background: #50afbd;">
				<div class="slideInLeft">
					<div class="wow bounceInLeft"><img src="../../assets/images/solution/stadium/stadium_02.png" /></div>
				</div>
				<div class="slideInRightN">
					<div class="wow flipInX"><img src="../../assets/images/solution/stadium/stadium_01_title.png" /></div>
				</div>
			</div>
			<div class="row" style="background: #97ddd9;">

				<div class=" slideInRightN">
					<div class="wow bounceInLeft "><img src="../../assets/images/solution/stadium/stadium_03_title.png" /></div>
				</div>
				<div class="slideInLeft">
					<div class="wow  bounceInRight"><img src="../../assets/images/solution/stadium/stadium_03.png" /></div>
				</div>
			</div>

		</div>
		<v-footer></v-footer>
	</div>

</template>

<script>
	import Header from '../../components/header';
	import Hfooter from '../../components/Hfooter.vue';
	export default{
		name:'schoolonline',
		components:{
			'v-header': Header,
			'v-footer': Hfooter
		},
		methods:{
			schoolpath(){
				var schoolUrl = 'http://39.108.49.151:8088/index';
				window.open(schoolUrl, '_blank');
			}
		}
	};
</script>

<style>
</style>
